import React, { useState, useRef, useCallback } from 'react';
import './style.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { navigate } from 'gatsby-plugin-intl';
import BtnSlider from './BtnSlider';
import Button from '../Button/Button';
import mask from '../../../assets/mask.svg';
import shadow from '../../../assets/Shadow.svg';
import mobileMask from '../../../assets/icons/mobileMask.svg';
import mobileShadow from '../../../assets/icons/mobileShadow.svg';

export default function Slider({
  data,
  ctaGoTo,
  goToVisibile = true,
  isSubtitleH1,
}) {
  const breakpoints = useBreakpoint();
  const [slideIndex, setSlideIndex] = useState(1);
  const sliderRef = useRef(null);

  const nextSlide = useCallback(() => {
    if (slideIndex !== data.filter((o) => !(!o.imageBackground && !o.slideImage)).length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === data.filter((o) => !(!o.imageBackground && !o.slideImage)).length) {
      setSlideIndex(1);
    }
  }, [data, slideIndex]);

  const prevSlide = useCallback(() => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(data.filter((o) => !(!o.imageBackground && !o.slideImage)).length);
    }
  }, [data, slideIndex]);

  const handleBtnClick = (slideData) => {
    navigate(slideData.buttonUrl);
  };
  // const moveDot = (index) => {
  //   setSlideIndex(index);
  // };

  return (
    <div className="containerSlider">
      {data.filter((o) => !(!o.imageBackground && !o.slideImage)).map((obj, index) => (
        <div ref={sliderRef}>
          <div
            className={slideIndex === index + 1 ? 'slide active-anim' : 'slide'}
          >
            <img
              alt="sliderIMG"
              src={
                // eslint-disable-next-line no-nested-ternary
                obj.imageBackground
                  ? obj.imageBackground
                  : obj.slideImage
                    ? obj.slideImage
                    : null
              }
              className={
                obj.overlayChecked ? 'mainSliderIMG' : 'mainSliderIMG_noOverlay'
              }
            />
            {obj.overlayChecked && (
              <img
                alt="sliderIMG"
                src={breakpoints.xs ? mobileMask : mask}
                className="maskIMG"
              />
            )}
            {obj.overlayChecked && (
              <img
                alt="sliderIMG"
                src={breakpoints.xs ? mobileShadow : shadow}
                className="shadowIMG"
              />
            )}
            {ctaGoTo && (
              <div
                className={
                  slideIndex === index + 1
                    ? 'sliderText active-anim'
                    : 'sliderText'
                }
              >
                <div
                  style={{ width: 'fit-content', maxWidth: 1200, margin: '0 auto' }}
                >
                  <div className="sliderTextTitle">{obj.author}</div>

                  <div className="sliderTextDesc">
                    {isSubtitleH1 ? (
                      <h1
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 300,
                          fontSize: breakpoints.md ? 30 : 40,
                          lineHeight: breakpoints.md ? '34px' : '44px',
                          maxWidth: breakpoints.sm ? 320 : '100%',
                          margin: '0 auto',
                        }}
                      >
                        {obj.title}
                      </h1>
                    ) : (
                      <div
                        style={{
                          maxWidth: breakpoints.md ? 300 : '100%',
                          margin: '0 auto',
                        }}
                      >
                        {obj.title}
                      </div>
                    )}
                  </div>
                  {obj.buttonUrl && (
                  <div
                    style={{
                      maxWidth: 410,
                      margin: breakpoints.smm && '0 auto',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      data-action="cta scopri"
                      data-name-cta={obj.buttonLabel}
                      label={obj.buttonLabel}
                      handleClick={() => handleBtnClick(obj)}
                      style={{
                        backgroundColor: '#D75F2B',
                        color: 'white',
                        width: 'max-content',
                      }}
                    />
                  </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}

      {data.length >= 2 && <BtnSlider moveSlide={nextSlide} direction="next" />}
      {data.length >= 2 && <BtnSlider moveSlide={prevSlide} direction="prev" />}

      {/* <div className="container-dots">
                {Array.from({length: dataSlider.length}).map((item, index) => (
                    <div
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    ></div>
                ))}
            </div> */}

      {ctaGoTo && goToVisibile && (
        <div className="container-dots">
          <div
            // onClick={() => {}}
            className="dotSlider"
          >
            <KeyboardArrowDownIcon
              onClick={ctaGoTo}
              style={{
                fontSize: !breakpoints.md ? '70px' : '40px',
                color: '#003F6E',
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
