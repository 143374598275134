import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Homepage from '../components/Homepage/Homepage';
import MainLayout from '../layaouts/MainLayout/MainLayout';

function Home({ pageContext }) {
  const {
    data,
    projects,
  } = pageContext;

  return (
    <MainLayout>
      <Homepage data={data} projects={projects} />
    </MainLayout>
  );
}

export default injectIntl(Home);
