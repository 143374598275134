import React from 'react'
import './style.css'
import homeArrow from '../../../assets/icons/homeArrow.svg'

export default function BtnSlider({ direction, moveSlide }) {
  return (
    <button
      type="button"
      onClick={moveSlide}
      className={direction === 'next' ? 'btn-slide next' : 'btn-slide prev'}
    >
      {direction === 'next' ? (
        <img src={homeArrow} style={{height:70, transform: 'rotate(-180deg)'}} />
        
      ) : (
        <img src={homeArrow} style={{height:70}} />
      )}
    </button>
  )
}
