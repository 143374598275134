import React, { useMemo } from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import './style.css';
import { map } from 'lodash';
import Button from '../Forms/Button/Button';

export default function Promotori({ promoters }) {
  const {
    buttonLabelPromoters,
    buttonUrlPromoters,
    logoPromotors,
    firstLogoPromoters,
    secondLogoPromoters,
  } = promoters;
  const intl = useIntl();
  const promotersImg = useMemo(() => map(
    [
      firstLogoPromoters,
      secondLogoPromoters,
    ],
    (img) => (
      <img
        key={img}
        alt="imgPromotori"
        className="prom"
        src={img}
      />
    ),
  ), [firstLogoPromoters, secondLogoPromoters]);

  const promotorsSliderLogo = useMemo(
    () => logoPromotors && logoPromotors.map((singleLogo) => (
      singleLogo.logo && (
        <div className="singleLogo">
          <img
            key={singleLogo}
            alt="imgPromotori"
            // className="singleLogo"
            src={singleLogo.logo}
          />
        </div>
      )
    )), [logoPromotors],
  );

  // const handleClick = useCallback(() => {

  //   navigate(buttonUrlPromoters);
  // }, []);

  return (
    <div className="promotersContainer">
      {
        promotorsSliderLogo
          ? (
            <div className="promotorsSliderLogo">
              {promotorsSliderLogo}
            </div>
          )
          : null
      }
      {firstLogoPromoters && secondLogoPromoters
        ? (
          <div className="promoters">
            {promotersImg}
          </div>
        )
        : null}

      {
        buttonUrlPromoters && buttonLabelPromoters
          ? (
            <Link to={buttonUrlPromoters} style={{textDecoration: 'none'}}>
              <Button
                data-action="cta scopri" data-name-cta={buttonLabelPromoters ? buttonLabelPromoters : intl.formatMessage({ id: 'btn_scopri_di_piu' })}
                style={{ color: 'white', backgroundColor: '#D75F2B'}}
                label={buttonLabelPromoters ? buttonLabelPromoters : intl.formatMessage({ id: 'btn_scopri_di_piu' })}
              />
            </Link>
          )
          : null
      }
    </div>
  );
}
