import React, {
  useCallback, useMemo, useRef, useEffect,
} from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { map, reduce } from 'lodash';
import Slider from '../Forms/Slider/Slider';
import './style.css';
import PromotoriSvg from '../../assets/icons/promotori.svg';
import MapSvg from '../../assets/icons/map.svg';
import albero from '../../assets/icons/albero.svg';

import BlockLayout from '../../layaouts/BlockLayout/BlockLayout';
import Promotori from '../Promotori/Promotori';
import ImageBlock from '../ImageBlock/ImageBlock';
import Mappa from '../Mappa/Mappa';
import MetaTags from '../Header/MetaTags';

export default function Homepage({ data, projects }) {
  const intl = useIntl();
  const features = useMemo(
    () => map(projects[intl.locale], (proj) => {
      const [icons, categories] = reduce(
        proj.categories,
        (acc, { name, acf }) => {
          acc[0].push(acf['Category Icon'].categoryIcon);
          acc[1].push(name);
          return acc;
        },
        [[], []],
      );

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(proj.acf.map.longitude),
            parseFloat(proj.acf.map.latitude),
          ],
        },
        properties: {
          title: proj.title.rendered,
          iconSize: [50, 50],
          // subTitle: 'Loren asd asd',
          btnLabel: intl.formatMessage({ id: 'btn_leggi_tutto' }),
          footerIcon: icons,
          footerCard: categories.join(' , '),
          description: proj.acf.projectPreview.projectExcerpt,
          url: proj.acf.projectPreview.projectImage,
          icon: MapSvg,
          slug: `/${proj.slug}`,
          color: '#003F6E',
        },
      };
    }),
    [projects, intl],
  );

  const {
    homepageSlider,
    promoters,
    companyBlock,
    historyBlock,
    ourProjects,
  } = data[intl.locale].acf;
  const acf = homepageSlider.slider;
  const { yoast_head_json } = data[intl.locale];

  const historyRef = useRef(null);
  const ctaGoTo = useCallback(() => {
    historyRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [historyRef]);

  useEffect(() => {
    const mylist = document.getElementById('homepage');
    const divs = mylist.children;
    const listitems = [];
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i));
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id');
      const compB = b.getAttribute('id');
      return compA < compB ? -1 : compA > compB ? 1 : 0;
    });
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i]);
    }
  }, []);

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="homepage">
        {acf.length > 0 && (
        <div id={homepageSlider.positionSlider}>
          <Slider data={acf} ctaGoTo={ctaGoTo} isSubtitleH1 />
        </div>
        )}

        {(promoters.firstLogoPromoters && promoters.secondLogoPromoters) && (
        <div id={promoters.positionPromoters}>
          <div ref={historyRef} />
          <BlockLayout
            style={{ backgroundImage: `url(${albero})` }}
            icon={PromotoriSvg}
            title={intl.formatMessage({ id: 'home_promotori_block_title' })}
            sub={promoters.descriptionPromoters}
          >
            <Promotori promoters={promoters} />
          </BlockLayout>
        </div>
        )}

        {(historyBlock.titleHistory && historyBlock.blockImageHistory) && (
        <div id={historyBlock.positionHistory}>
          <ImageBlock
            isTitileH2
            type="1"
            img={historyBlock.blockImageHistory}
            label={historyBlock.titleHistory}
            btnlabel={historyBlock.buttonLabelHistory}
            goto={historyBlock.buttonUrlHistory}
          />
        </div>
        )}

        {features && (
        <div id={ourProjects.positionProjects}>
          <BlockLayout
            isTitleH3
            icon={ourProjects.iconProjects}
            title={ourProjects.titleProjects}
          >
            <Mappa features={features} />
          </BlockLayout>
        </div>
        )}

        {(companyBlock.companyTitle && companyBlock.companyBackgroundImage) && (
        <div id={companyBlock.positionCompany}>
          <ImageBlock
            type="2"
            img={companyBlock.companyBackgroundImage}
            label={companyBlock.companyDescription}
            title={companyBlock.companyTitle}
            btnlabel={companyBlock.companyButtonLabel}
            goto={companyBlock.companyButtonLink}
            id={companyBlock.positionCompany}
          />
        </div>
        )}
      </div>
    </>
  );
}
